import dayjs from 'dayjs';
import { graphql, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import * as React from 'react';

import { GoBackLink } from '../components/go-back-link/go-back-link';
import Layout from '../components/layout/layout';
import { PostedOnDate } from '../components/posted-on-date/posted-on-date';
import { ArrowIcon } from '../components/svg/arrow-icon';
import { DATETIME_VALID_DATE_STRING_FORMAT } from '../const/app.const';
import { InternationalizationConfig } from '../model/i18n-config.interface';
import { MdxBlogPostQueryResult } from '../model/mdx-post-metadata.interface';
import { blogPost, headerImage, imgAndDescription, listFooter, listHeader, postList } from '../styles/post-list.module.css';
import { getRelativePath } from '../utils/app.utils';

/**
 * Context data passed by createPage() function in `gatsby-node.js` file.
 */
interface PostListPageContext {
    currentPage: number;
    numPages: number;
}

const PostListTemplate: React.FunctionComponent<PageProps<MdxBlogPostQueryResult, PostListPageContext>> = (props) => {
    const { locale, config } = useLocalization();
    const dateFormat = (config as InternationalizationConfig).find(c => c.code === locale)?.dateFormat;
    // Only show posts for the current locale (English or German)
    const posts = props.data.allMdx.nodes.filter(node => node.fields.locale === locale);

    const { currentPage, numPages } = props.pageContext;
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === numPages
    const slug = '/post-list/';
    const currentPagePath = slug + (currentPage === 1 ? '' : currentPage + '/');
    const previousPagePath = slug + (currentPage - 1 === 1 ? '' : currentPage - 1 + '/')
    const nextPagePath = slug + (currentPage + 1) + '/';
    // This is necessary so that static html file created with static site generation includes correct meta data.
    const relativePath = getRelativePath(locale, currentPagePath);

    const description = locale === 'en'
        ? 'List of all Blog Posts'
        : 'Liste aller Blog-Artikel';
    const title = locale === 'en'
        ? `${description}, Page ${currentPage} – Oida, is des org!`
        : `${description}, Seite ${currentPage} – Oida, is des org!`;
    const altText = locale === 'en'
        ? 'Colourfully painted face. As colourful as the life of a web developer.'
        : 'Bunt bemaltes Gesicht. So vielfältig wie das Leben als Web-Entwickler.';
    const navLabel = locale === 'en' ? 'Pagination' : 'Paginierung';
    const nextPageLabel = locale === 'en' ? 'Next Page' : 'Nächste Seite';
    const previousPageLabel = locale === 'en' ? 'Previous Page' : 'Vorherige Seite';
    const ofText = locale === 'en' ? 'of' : 'von';
    const readMore = locale === 'en' ? 'Read More' : 'Weiterlesen';

    // Trigger link when user clicks on another part of a blog post
    const onClickPostHandler = (event: any, id: string): void => {
        if (event.target.nodeName !== 'A') {
            document?.getElementById(id)?.click();
        }
    }

    const createPagination = (): JSX.Element => (
        <nav aria-label={navLabel}>
            <ul>
                <li aria-hidden={isFirstPage}>
                    {isFirstPage ? null : (
                        <LocalizedLink
                            to={previousPagePath}
                            language={locale}
                            aria-label={previousPageLabel}
                        >
                            <ArrowIcon />
                            <span>{previousPageLabel}</span>
                        </LocalizedLink>
                    )}
                </li>
                <li>
                    <span aria-hidden="true">{`${currentPage} / ${numPages}`}</span>
                    <span className="sr-only">{`${currentPage} ${ofText} ${numPages}`}</span>
                </li>
                <li aria-hidden={isLastPage}>
                    {isLastPage ? null : (
                        <LocalizedLink
                            to={nextPagePath}
                            language={locale}
                            aria-label={nextPageLabel}
                        >
                            <span>{nextPageLabel}</span>
                            <ArrowIcon />
                        </LocalizedLink>
                    )}
                </li>
            </ul>
        </nav>
    );

    const getRelativePathToThumbnail = (imageUrl: string): string => {
        const indexOfLastSlash = imageUrl.lastIndexOf('/');
        const fileName = imageUrl.substring(indexOfLastSlash + 1);
        // Use thumbnail version of JPG with reduced file size
        return '/' + fileName.replace('.jpg', '_thumbnail.jpg');
    }

    return (
        <Layout
            title={title}
            description={description}
            langSwitchPath={currentPagePath}
            relativePath={relativePath}
            removeSidePadding={true}
        >
            <section className={listHeader}>
                <h1>{description}</h1>
                <StaticImage
                    className={headerImage}
                    src="../images/pexels-sharon-mccutcheon-painted-face.jpg"
                    alt={altText}
                    placeholder="dominantColor"
                    layout="fullWidth"
                />
            </section>
            <section className={postList}>
                {createPagination()}
                {posts.map((post, index) => (
                    <article
                        className={blogPost}
                        key={'blog-post-preview-' + index}
                        onClick={(event) => onClickPostHandler(event, 'blog-post-link-' + index)}
                    >
                        <h2>{post.frontmatter.title}</h2>
                        <div className={imgAndDescription}>
                            {post.frontmatter.image ? (
                                <img
                                    src={getRelativePathToThumbnail(post.frontmatter.image)}
                                    alt=""
                                    loading="lazy"
                                />
                            ) : null}
                            <div>
                                <p>{post.frontmatter.description}</p>
                                <PostedOnDate
                                    dateLabel={dayjs(post.frontmatter.date).format(dateFormat)}
                                    datetimeValue={dayjs(post.frontmatter.date).format(DATETIME_VALID_DATE_STRING_FORMAT)}
                                    isBottom={true}
                                />
                            </div>
                        </div>
                        <LocalizedLink
                            id={'blog-post-link-' + index}
                            to={`/${post.slug}`}
                            language=""
                            title={post.frontmatter.title}
                        >
                            <span aria-hidden="true">&rarr;&nbsp;</span>{readMore}
                        </LocalizedLink>
                    </article>
                ))}
                {createPagination()}
            </section>
            <section className={listFooter}>
                <GoBackLink />
            </section>
        </Layout>
    );
};

export default PostListTemplate;

export const postListQuery = graphql`
    query GetAllBlogPosts($skip: Int!, $limit: Int!) {
        allMdx(
            sort: {fields: frontmatter___date, order: DESC}
            limit: $limit
            skip: $skip
        ) {
            nodes {
                id
                fields {
                    locale
                }
                frontmatter {
                    date
                    description
                    image
                    title
                }
                slug
            }
        }
    }
`;
